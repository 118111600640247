<template>
<div class="content">
  <div class="page-title pt-0 pb-3">
    <h4 class="font-weight-semibold">{{ pageTitle }}</h4>
  </div>

  <div class="row g-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="d-flex align-items-center">
            <h5 class="card-title font-weight-semibold mr-auto">Verifikasi Akun SATUSEHAT Pasien</h5>
          </div>
        </div>

        <ValidationObserver ref="VFormSSKYC">
          <b-form @submit.prevent="doSubmit()">
            <b-tabs nav-class="nav-tabs-highlight nav-justified mb-0" v-model="formTab">
              <b-tab title="Tambah Manual">
                <div class="card-header pl-3">
                  <h6 class="card-title font-weight-semibold">Masukkan Data Manual</h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="verifNamaLengkap">Nama Lengkap <small class="text-secondary">(Sesuai KTP)</small><strong class="text-danger">*</strong></label>
                        <b-form-input placeholder="e.g. Ahmad Yahya" v-model="row.agent_name" />

                        <VValidate name="Nama Lengkap" rules="required" v-model="row.agent_name" v-if="formTab == 0" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="verifNik">NIK<strong class="text-danger">*</strong></label>
                        <b-form-input placeholder="e.g. 3374116511730004" v-model="row.agent_nik" />
                        
                        <VValidate name="NIK" rules="required" v-model="row.agent_nik" v-if="formTab == 0" />
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Ambil Data Dari Pasien">
                <div class="card-header pl-3">
                  <h6 class="card-title font-weight-semibold">Ambil dari Data Pasien</h6>
                </div>
                <div class="card-body">
                  <div>
                    <label for="">Filter Data Pasien</label>
                    <div class="row">
                      <div class="col-md">
                        <div class="form-row">
                          <div class="col-md-3">
                            <b-form-input placeholder="Nama Pasien" autocomplete="off" v-model="filter.name" />
                          </div>
                          <div class="col-md-3">
                            <b-form-input placeholder="Nomor Rekam Medis" autocomplete="off" v-model="filter.rm" />
                          </div>
                          <div class="col-md-3">
                            <b-form-input placeholder="Alamat" autocomplete="off" v-model="filter.adr" />
                          </div>
                          <div class="col-md-3">
                            <datepicker v-model="filter.dob" input-class="form-control transparent" placeholder="Tanggal Lahir" class="my-datepicker" calendar-class="my-datepicker_calendar">
                            </datepicker>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-auto">
                        <div class="d-flex">
                          <a href="javascript:;" class="btn-new py-1 h-auto mr-1" @click="getPatient()">Cari</a>
                          <a href="javascript:;" class="btn-new py-1 h-auto" @click="resetFilter()">Reset</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div data-select2-id="267">
                    <label>Pilih Pasien <small class="txt_mandatory">*</small></label>
                    <v-select class="med-selection select-paging" v-model="row.patient_select" label="nik" placeholder="-- Pilih Pasien --" :options="patientData" :reduce="v=> ({ agent_name: v.name, agent_nik: v.nik })" :loading="loading.patient">
                      <template #selected-option="{ name, nik }">
                        <span class="font-weight-semibold d-block mr-1">{{ name }} </span> (<span>{{ nik }}</span>)
                      </template>

                      <template #option="{ name, nik }">
                        <span class="font-weight-semibold">{{ name }}</span>
                        <br/>
                        <span>{{ nik }}</span>
                      </template>

                      <template #list-footer>
                        <li class="list-paging">
                          <div class="d-flex align-items-center justify-content-between">
                            <b-button 
                              size="sm" variant="transparent" 
                              class="btn-icon bg-blue-300" 
                              @click="getPatient({
                                ...patientPage,
                                page: +(patientPage.page) - 1
                              })"
                              :disabled="+(patientPage.page) == 1"
                            >
                              <i class="icon-arrow-left22 text-white"></i></b-button>
                            <small>Halaman {{ patientPage.page }} dari {{ Math.ceil(patientPage.total/patientPage.per_page) }}</small>

                            <b-button 
                              size="sm" variant="transparent" 
                              class="btn-icon bg-blue-300"
                              @click="getPatient({
                                ...patientPage,
                                page: +(patientPage.page) + 1
                              })"
                              :disabled="+(patientPage.page) == Math.ceil(patientPage.total/patientPage.per_page)"
                            >
                              <i class="icon-arrow-right22 text-white"></i></b-button>
                          </div>
                        </li>
                      </template>
                    </v-select>

                    <VValidate name="Pasien" v-model="row.patient_select" rules="required" v-if="formTab == 1" />
                  </div>
                </div>
              </b-tab>
            </b-tabs>

            <div class="card-footer">
              <div class="text-right">
                <button type="submit" class="btn btn-primary btn-labeled btn-labeled-left">
                  <b><i class="icon-paperplane"></i></b>
                  <span>Verifikasi</span>
                </button>
              </div>
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Gen from '@/libs/Gen'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'

const _ = global._

export default {
  extends: GlobalVue,
  props: {
    hasAddPerawat: Boolean,
    hasFillCriteria: Boolean,
  },
  components: {
    Datepicker
  },
  data() {
    return {
      dataModal: {},
      filter: {

      },

      loading: {
        patient: true
      },

      patientData: [],
      patientPage: {
        per_page: 6,
        page: 1,
        total: 0
      },

      formTab: 0
    }
  },

  methods: {
    doSubmit(){
      this.$refs['VFormSSKYC'].validate().then(ok => {
        console.log(ok)
        if(!ok) return

        this.$swal.fire({
          title: 'Loading...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        let payload = {}
        if(this.formTab == 1){
          const { agent_name, agent_nik } = this.row.patient_select
          payload = { agent_nik, agent_name }
        } else {
          payload = this.row
        }

        Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: "verify-patient",
            ...payload
          }
        }).then(res => {
          this.$swal.close()
          const { url } = res.data.data

          this.$swal({
            title: res.data.message,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
          .then(result => {
            if (result.value) {
              window.open(url, '_blank').focus()
            }
          })
        }).catch(() => {
          return this.$swal({
            icon: 'error',
            text: 'Tidak dapat verifikasi data pasien'
          })
        })
      })
    },
    resetFilter() {
      this.$set(this, 'filter', {})

      setTimeout(() => { 
        this.getPatient()
      }, 0)
    },
    getPatient(opt = this.patientPage) {
      this.$set(this.loading, 'patient', true)
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-patient",
            ...opt,
            ...this.filter,
          }
        }
      ).then(res => {
        if(this.row.patient_select){
          const { agent_nik, agent_name } = this.row.patient_select
          const patientData = res.data.patient.filter(pt => pt.nik != agent_nik)
          this.$set(this, 'patientData', 
            [{ name: agent_name, nik: agent_nik }, ...patientData]
          )
        } else {
          this.$set(this, 'patientData', res.data.patient)
        }

        this.$set(this, 'patientPage', res.data.patient_page)
        this.$set(this.loading, 'patient', false)
      })
    }
  },

  mounted() {
    this.apiGet()
    this.getPatient()
  },

  watch: {
    $route() {
      this.apiGet()
      this.getPatient()
    },
    formTab(){
      this.$nextTick(() => {
        this.$refs["VFormSSKYC"].reset()
      })
    }
  },
}
</script>
